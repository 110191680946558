.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 95px;
    padding: 0 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #4a90e2;
  }

  .home-link {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .right-section {
    display: flex;
    align-items: center;
  }

  .logout-button {
    margin-left: 20px;
  }
