.conversation-form {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    margin: 0 auto;
}

.conversation-form h1 {
    font-size: 24px;
    margin-bottom: 20px;
}

.conversation-form .form-group {
    margin-bottom: 15px;
}

.conversation-form label {
    font-weight: bold;
}

.conversation-form ul {
    list-style: none;
    padding: 0;
    margin: 0;
    color: red;
}

.conversation-form button[type="submit"] {
    background-color: #007bff;
    border: none;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.form-check {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.form-check label {
    margin-left: 10px;
}
